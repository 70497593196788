import { VariantProps, cva } from 'class-variance-authority';
import { isFunction } from 'lodash-es';
import React from 'react';
import { Menu, MenuItem, MenuItemProps, MenuProps, SeparatorProps } from 'react-aria-components';
import { twMerge } from 'tailwind-merge';
import { text } from '../../electrons/text';
import { Separator } from '../separator/separator';

interface _MenuItemProps extends MenuItemProps {
  slotRight?: React.ReactNode;
  slotLeft?: React.ReactNode;
}

type _MenuProps<T extends object> = MenuProps<T> & VariantProps<typeof menuItem>;

const menuItem = cva(
  'rac-hover:bg-grey-200 rac-disabled:!text-grey-500 rac-disabled:cursor-not-allowed relative flex min-w-40 cursor-pointer items-center gap-x-1 rounded-lg bg-white px-2 transition focus:outline-none',
  {
    variants: {
      size: {
        small: twMerge(text({ variant: 'p6' }), 'py-2 leading-4'),
        large: twMerge(text({ variant: 'p5' }), 'py-3 leading-6'),
      },
      variant: {
        default: '!text-grey-800',
        destructive: '!text-negative-500',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'large',
    },
  },
);

const MenuVariantContext = React.createContext<VariantProps<typeof menuItem>>({});

function _Menu<T extends object>({ size, children, className, ...props }: _MenuProps<T>) {
  return (
    <MenuVariantContext.Provider value={{ size }}>
      <Menu
        className={twMerge('flex flex-col gap-y-1 bg-white outline-none', className)}
        {...props}>
        {children}
      </Menu>
    </MenuVariantContext.Provider>
  );
}

function _MenuItem({
  slotRight,
  slotLeft,
  children,
  className,
  variant,
  ...props
}: _MenuItemProps & VariantProps<typeof menuItem>) {
  const { size } = React.useContext(MenuVariantContext);
  const textValue = props.textValue || (typeof children === 'string' ? children : '');

  return (
    <MenuItem
      {...props}
      className={(bag) =>
        twMerge(menuItem({ size, variant }), isFunction(className) ? className(bag) : className)
      }
      textValue={textValue}>
      {(bag) => (
        <>
          {slotLeft && slotLeft}
          {isFunction(children) ? children(bag) : children}
          {slotRight && <span className="ml-auto">{slotRight}</span>}
        </>
      )}
    </MenuItem>
  );
}

_Menu.Item = _MenuItem;

function _MenuSeparator(props: SeparatorProps) {
  return <Separator {...props} />;
}

_Menu.Separator = _MenuSeparator;

export { _Menu as Menu, _MenuItem as MenuItem };
export type { _MenuProps as MenuProps, _MenuItemProps as MenuItemProps };
